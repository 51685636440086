@import "../../../scss/queries.scss";
.section {
  position: relative;
  padding: 0px 50px;
  // height:"85%";
  overflow-y: auto;
  overflow-x: hidden;
  @include smd {
    padding: 0px 30px;
    overflow-y: hidden;
  }

  @include md {
    padding: 0px 45px;
  }

  .header {
    text-align: center;

    h2 {
      font-size: 42px;
      line-height: 57.6px;
      font-weight: 300;
      margin-top: 40px;
      margin-bottom: 12px;
      @include smd {
        font-size: 35px;
        margin-top: 0px;
      }
    }

    p {
      font-weight: 300;
      font-size: 0.75rem;
    }
  }

  .main {
    &.disabled {
      & > * {
        color: #c7c6c6;
      }
      & > label {
        border: 1px solid #c7c6c6 !important;
      }
    }
    margin-top: 35px;
    .mainTitle {
      font-weight: 500;
      display: block;
      margin-bottom: 16px;
      font-size: 0.85rem;
    }

    .buttons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 12px;
      row-gap: 12px;
    }
  }

  @include smd {
    padding: 0px 40px;
    // height: 44vh;
    font-size: 8px;
    overflow-y: auto;
  }
}
