@import '../../scss/queries.scss';

.infoSlide {
  position: fixed;
  top: 0;
  // left: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 3000;
  background-color: #fff;
  color: black;
  height: 100vh;
  font-size: 30px;
  width: 400px;
  text-align: left;
  padding: 0px 50px;
  overflow-y: scroll;
  margin-top: 8vh;

  @include md {
    padding: 0px 45px;
  }

  @include smd {
    padding: 0px 30px;
    position: fixed;
    width: 100%;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    font-size: 15px;
  }

  .infoCloseButton {
    cursor: pointer;
    // position: relative;
    // width: 100%;
    margin: 25px auto 16px;
    // right: 0px;
    // font-weight: 500;
    // border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      margin: 0px;
      font-weight: 800;
      margin-right: 8px;
    }

    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      padding: 4px;
      .svg {
        width: 24px;
      }
    }
  }

  .infoText {
    margin-bottom: 50px;
  }
}

.ibm_errechne {
  margin-bottom: 45px;
  h4 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0px;
    margin-bottom: 20px;
  }
  p {
    font-size: 0.85rem;
    font-weight: 300;
    &:last-child {
      span {
        font-size: 1.3rem;
        font-weight: 500;
        margin-left: 5px;
      }
    }
  }

  .contact_info {
    display: flex;
    margin-top: 20px;
    align-items: center;

    img {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      border: 1px solid black;
      margin-right: 25px;
      object-fit: cover;
    }

    div:nth-child(2) {
      h3 {
        margin-bottom: 0px;
        font-weight: 500;
      }
      p {
        font-weight: 400;
      }
    }
  }
  .restart_btns {
    display: flex;
    margin-top: 20px;
  }

  input {
    appearance: none;
    border: 0px;
    border-bottom: 1px solid;
    width: 50px;
    outline: none;
    color: black;
  }
  span {
    font-size: 0.75rem;
    font-weight: 300;
  }
  div {
    margin-bottom: 5px;
  }
}

.ibm_weiter {
  h5 {
    font-size: 0.85rem;
    margin-bottom: 15px;
  }
  p {
    font-size: 0.75rem;
  }
}

.farbe_section {
  padding: 0px 50px;
  height: 85%;
  // width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include smd {
    padding: 0px 30px;
  }

  @include md {
    padding: 0px 20px;
  }

  .top {
    text-align: left;

    h4 {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 10px;
    }

    p {
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 24px;

      span {
        text-decoration: underline;
      }
    }
  }

  .main {
    margin-top: 35px;

    span {
      font-weight: 500;
      display: block;
      margin-bottom: 16px;
      font-size: 0.85rem;
    }

    .button {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 10px;
      row-gap: 10px;

      //    .buttonImage{
      //     width: 100%;
      //    }
    }
  }

  Button {
    position: relative;
    // z-index: 1;
    width: 100%;
    margin: 50px auto 16px;
    bottom: 0px;

    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      padding: 4px;
    }
  }
}
