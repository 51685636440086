@import '../../../../scss/queries.scss';
.section {
  // position: relative;
  padding: 0px 50px;
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
  @include xsm {
    padding: 0px 30px;
  }
  .header {
    text-align: center;
    h2 {
      font-size: 42px;
      line-height: 57.6px;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 12px;
    }
    p {
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 24px;
      span {
        text-decoration: underline;
      }
    }
  }
  .main {
    margin-top: 55px;
    &-first {
      margin-top: 35px;
    }
    p {
      font-weight: 500;
      display: block;
      margin-bottom: 16px;
      font-size: 0.85rem;
      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .button {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 10px;
      row-gap: 10px;
      & > div:nth-child(3n + 2) {
        display: flex;
        justify-content: center;
      }
      > div:nth-child(3n) {
        display: flex;
        justify-content: flex-end;
      }
      @include xmd {
        column-gap: 35px;
        row-gap: 30px;
      }
    }
  }
  Button {
    position: relative;
    // z-index: 1;
    width: 100%;
    margin: 50px auto 16px;
    bottom: 0px;
    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      padding: 4px;
    }
  }
}
