.navList  {
    display: flex;
    justify-content: center;
    margin-top:10px;
    list-style: none;
    height: 5vh;

    // width: 60%;

    .navItems {
      font-size: .95rem;
      font-weight: 400;
      color: #C7C6C6;
      cursor: pointer;
      margin-left: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      &--first {
        margin-left: 0px!important;
      }
  
  
      &:focus, &:active [role="active"]{
        color:black
      }
  
  
      span{
        margin-left: 14px;
        display: flex;
        background-color: #C7C6C6;;
        display: block;
        width: 16px;
        height: 2px;
        border-radius: 1px;
      }
  
      &:last-child img,&:last-child span {
        display: none;
      }
      
    }
  
    .active {
      font-size: .95rem;
      font-weight: 400;
      color: black;
      cursor: pointer;
      margin-left: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      span{
        margin-left: 14px;
        display: flex;
        background-color: black;
        display: block;
        width: 16px;
        height: 2px;
        border-radius: 1px;
      }
  
      &:last-child img,&:last-child span {
        display: none;
      }
    }
  }
  
  
  
  
  