@import '../../../scss/queries.scss';
.section {
  height: 92vh;
  overflow: none;
  @include smd {
    height: 100%;
  }
  .header {
    text-align: center;
    h2 {
      font-size: 42px;
      line-height: 57.6px;
      font-weight: 300;
      margin-top: 40px;
      margin-bottom: 16px;
    }
    p {
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 24px;
    }
  }
  .main {
    margin-top: 35px;
    span {
      font-weight: 500;
      display: block;
      margin-bottom: 16px;
      font-size: 0.85rem;
    }
    .button {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 12px;
      row-gap: 12px;
    }
  }
  Button {
    position: relative;
    width: 100%;
    margin: 25px auto 16px;
    bottom: 0px;
    font-weight: 500;
    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      padding: 5px;
      .svg {
        width: 24px;
      }
    }
  }
}
