@import '../../scss/queries.scss';

.container {
  display: flex;
  width: 100%;
  height: 8vh;
  justify-content: space-between;
  align-items: center;
  padding: 8px 50px 8px 28px;
  border-bottom: 1px solid #c7c6c6;

  .leftHeader {
    display: flex;
    width: 45%;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 400;
      cursor: pointer;
    }
    img {
      margin-right: 30px;
    }
  }

  .logo {
    width: 150px;
    cursor: pointer;

    @include smd {
      order: 2;
    }
  }

  @include smd {
    position: fixed;
    z-index: 90000;
    background-color: #ffff;
    top: 0;
  }
}
