@import "../../../scss/queries.scss";

.section {
  position: relative;
  padding: 0px 50px;
  height: 84vh;
  // width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .header {
    text-align: center;

    h2 {
      font-size: 42px;
      line-height: 57.6px;
      font-weight: 300;
      margin-top: 40px;
      margin-bottom: 12px;
    }

  }

  .main {
    margin-top: 25px;
    p {
      font-weight:600;
      font-size: .8rem;
      span{
        height: 10px;
        width: 10px;
      }
    }

    h6{
      font-size: 1rem;
      font-weight: 400;
    }

    .toggleText{
      cursor: pointer;
      margin-top: 10px;
    }

    .subHeader{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      

      p {
        font-weight: 500;
        font-size: 1rem;
      }

    }

    .priceList{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
  
     
      h6 {
        font-weight:400;
        font-size: .85rem;
      }

    }

    .delivery{
      font-weight: 800;
      font-size: .6rem;
      margin-top: 20px;

      p{
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        font-size: 0.85rem;

        &::before{
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 100px;
            background-color: rgb(122, 240, 122);
            display: inline-block;
            margin-right: 8px;
        }
      }

    }

   

    // .button {
    //   display: grid;
    //   grid-template-columns: repeat(2, 1fr);
    //   column-gap: 12px;
    //   row-gap: 12px;
    //   margin-top: 16px;

    //   h6 {
    //     color: #635f5f;
    //     font-size: 10px;
    //   }

    //   .buttonText {
    //     width: 100%;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     border: 1px solid #2e2b2b;
    //     border-radius: 6px;
    //     padding: 14px;
    //     margin-bottom: 14px;
    //   }

    //   .buttonImage {
    //     width: 100%;
    //   }

    //   span {
    //     font-weight: 500;
    //     font-size: 14px;
    //     margin-bottom: 16px;
    //   }
    // }
  }

  .payment{
    display: flex;
    align-items: center;
    margin-top: 30px;

    img{
      width: 15%;
      height: 80%;
    }

    h5{
      font-size: .9rem;
      width: 85%;
      margin-left: 10px;
      color: rgb(104, 103, 103);

      b{
        color: black; 
      }
    }
  }


  Button {
    position: relative;
    width: 100%;
    margin: 25px auto 16px;
    bottom: 0px;

    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      padding: 5px;
      .svg {
        width: 24px;
      }
    }
  }

  @include smd {
    padding: 0px 30px;
    height: auto;
    font-size: 8px;
    overflow-y: hidden;
  }

  @include md {
    padding: 0px 45px;
  }
}
