@import "queries.scss";

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  margin: 0px;
  padding: 0px;
}
.container {
  width: 100%;
  height: 100vh;
  font-size: 50px;
  overflow-y: hidden;
  overflow-x: hidden;
  @include smd{
    height: 100%;
  }
}
.sub-container {
  display: flex;
  height: 92vh;
  @include smd {
    display: block;
    height: 100%;
    overflow-y: scroll;
    margin: 8vh auto 7vh;
  }
}

.error {
  color: rgb(231, 49, 0);
}

.text {
  font-size: 1.1rem;

  &--sm {
    font-size: 0.75rem;
  }
}

.low-opacity {
  opacity: 0.3;
}

.slideIn {
  transition: 1s;
  animation: slideIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.slideOut {
  transition: 1s;
  animation: slideOut 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

.bed-container {
  position: relative;
  width: 800px;
  height: 640px;
  transform-origin: center;
}

.bed-img {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
}
