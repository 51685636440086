@import '../../scss/queries.scss';
.container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 8vh;
  background-color: #94d0cf;
  color: #000000;
  font-weight: 500;
  padding: 18px 35px 15px;
  z-index: 99999;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  @include smd {
    position: fixed;
    padding: 12px 35px 15px;
    height: 7vh;
  }
  .info {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    line-height: 1.5rem;
    @include smd {
      font-size: 1.3rem;
      line-height: 1rem;
    }
    h4 {
      font-weight: 500;
    }
    span {
      font-weight: 600;
      &:nth-child(1) {
        font-size: 1.5rem;
        display: block;
        line-height: 1.5rem;
        @include smd {
          font-size: 0.9rem;
        }
      }
      &:nth-child(2) {
        font-size: 0.6rem;
      }
    }
  }
  .stoff {
    h4 {
      font-size: 1.1rem;
      line-height: 1.1rem;
    }
    span {
      &:nth-child(1) {
        font-size: 1.1rem;
        line-height: 1.1rem;
        @include smd {
          font-size: 1.1rem;
        }
      }
      &:nth-child(2) {
        font-size: 0.9rem;
      }
    }
  }
  p {
    text-align: right;
    font-size: 11px;
    margin: 0px;
  }
  @include smd {
    width: 100%;
  }
}
