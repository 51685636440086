@import '../../scss/queries.scss';
.main_container {
  position: absolute;
  left: calc(50% - 250px);
  top: calc(50% - 300px);
  @include xxl {
    top: calc(50% - 350px);
  }
  @include smd {
    left: calc(50% - 270px);
  }
}

.container {
  position: relative;
  display: flex;
  background-repeat: no-repeat;
  width: 550px;
  height: 440px;
  font-size: 10px;
  transform-origin: center;
  @include smd {
    // transform: scale(1) !important;
    // top: calc(50% - 233px) !important;
  }
  .loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.5);
    @include smd {
      height: 37vh;
      top: 8vh;
    }
  }
  .bed {
    position: relative;
    width: 518px;
    height: 436px;
    transform-origin: center;
    transform: scale(0.75) translate(10px, 95px);
    @include smd {
      transform: scale(0.85) translate(7px, 95px);
    }
  }
  .img {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    @include smd {
      object-fit: contain;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  .fade-in-image {
    animation: fadeIn 0.3s;
  }
}
