@import '../../../../scss/queries.scss';
.section {
  position: relative;
  padding: 0px 50px;
  height: 85%;
  // width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include md {
    padding: 0px 45px;
  }

  @include smd {
    padding: 0px 30px;
  }

  .header {
    text-align: center;

    h2 {
      font-size: 42px;
      line-height: 57.6px;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 12px;
    }

    p {
      font-weight: 300;
      font-size: 0.75rem;
    }

    // p{
    //     font-weight: 300;
    //     font-size:0.75rem;
    //     line-height: 24px;

    //     span{
    //         font-weight: 600;
    //         font-size: 16px;
    //         margin-left: 16px;
    //     }
    // }
  }

  .main {
    margin-top: 35px;
    span {
      font-weight: 500;
      display: block;
      // margin-bottom: 24px;
      margin-bottom: 16px;
      font-size: 0.85rem;
    }

    .button {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 12px;
      row-gap: 12px;
    }

    .buttonImage {
      height: 80px;
      object-fit: contain;
    }

    .legOptions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 12px;
      row-gap: 12px;
      p {
        font-size: 0.85rem;
        margin-bottom: 12px;
        font-weight: 500;
      }
    }
  }

  Button {
    position: relative;
    // z-index: 1;
    width: 100%;
    margin: 25px auto 16px;
    bottom: 0px;
    font-weight: 500;

    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      padding: 5px;
      .svg {
        width: 24px;
      }
    }
  }
}
