@import '../../scss/queries.scss';
.hamburger {
  display: none;

  p {
    font-size: 1.2rem;
    margin-left: 8px;
    font-weight: 500;
  }
  @include smd {
    display: flex;
    align-items: center;
    color: black;
    cursor: pointer;
  }
}

.nav_container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
  background-color: #c7c6c6;
  color: black;
  height: 100vh;
  font-size: 30px;
  width: 25vw;
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: left;
  overflow-y: scroll;
  overflow-x: auto;
  transform: translateX(100%);
  transition: transform 0.6s;

  h2 {
    font-weight: 300;
  }

  li {
    cursor: pointer;
    font-weight: 300;
    font-size: 1.7rem;
    margin-bottom: 10px;
  }
  @include smd {
    transform: translateX(-100%);
    width: 100vw;
    left: 0;
  }
}

.nav_active {
  transform: translateX(0);
  transition: all 0.6s;
}
.nav_close {
  text-align: right;
  font-size: 50px;
  cursor: pointer;
}

// .mobile_container {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 50;
//   background-color: #c7c6c6;
//   color: black;
//   height: 100vh;
//   font-size: 30px;
//   width: 20vw;
//   display: flex;
//   flex-direction: column;
//   padding: 30px;
//   align-items: left;
//   overflow-y: scroll;
//   overflow-x: auto;

//   h2 {
//     font-weight: 300;
//   }

//   li {
//     cursor: pointer;
//     font-weight: 300;
//     font-size: 1.7rem;
//     margin-bottom: 10px;
//   }
//   @include smd {
//     width: 100vw;
//   }
// }
// .mobile_navClose {
//   text-align: right;
//   font-size: 50px;
//   cursor: pointer;
// }

.mobile_navList {
  // display: none;
  display: flex;
  flex-direction: column;
  // align-items: left;
  list-style: none;
  padding: 30px 0px;
  border-bottom: 1px solid black;
  @include smd {
    // display: flex;
    // flex-direction: column;
    // // align-items: left;
    // list-style: none;
    // padding: 30px 0px;
    // border-bottom: 1px solid black;
  }
}

.mobile_socialIcons {
  display: none;

  p {
    font-size: 16px;
    margin-top: 24px;
  }
  @include smd {
    display: flex;
    flex-direction: column;
    padding: 50px 0px;
    align-items: center;
    justify-content: center;
  }
}

.mobile_socialIconsList * {
  display: none;
  @include smd {
    display: inline;
    padding: 5px;
    border-radius: 50%;
    font-size: 40px;
    color: #fff;
    background-color: black;
    margin-left: 10px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
  }
}

.iconNavList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  // width: 200px;
  & > div {
    height: 25px;
    width: 25px;
    display: block;
    cursor: pointer;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      display: block;
    }
    svg {
      cursor: pointer;
    }
  }

  @include smd {
    display: none;
  }
}

.navList {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;

  @include smd {
    display: none;
  }

  .navItems {
    font-size: 0.95rem;
    font-weight: 400;
    color: #c7c6c6;
    cursor: pointer;
    margin-left: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus,
    &:active [role='active'] {
      color: black;
    }

    span {
      margin-left: 14px;
      display: flex;
      background-color: #c7c6c6;
      display: block;
      width: 16px;
      height: 2px;
      border-radius: 1px;
    }

    &:last-child img,
    &:last-child span {
      display: none;
    }
  }

  .active {
    font-size: 0.95rem;
    font-weight: 400;
    color: black;
    cursor: pointer;
    margin-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 14px;
      display: flex;
      background-color: black;
      display: block;
      width: 16px;
      height: 2px;
      border-radius: 1px;
    }

    &:last-child img,
    &:last-child span {
      display: none;
    }
  }
}
