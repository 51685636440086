@import "../../../scss/queries.scss";

.modal {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(251, 250, 250 , 0.8) ;
  z-index: 2;

  @include smd {
    position: fixed;
    top: 0;
  }

  @include md {
    padding: 0px 45px;
  }
}

.modal_content {
  width: 60%;
  position: relative;
  background-color: rgba(255, 255, 255);
  padding: 10px 15px;
  text-align: center;

  @include smd {
    width: 85%;
  }

  @include md {
    width: 60%;
  }

  h5 {
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    font-size: 0.75rem;
    margin-bottom: 10px;
  
  }
  // p:first-child{
  //   margin-bottom: 15px;
  // }

  .buttons{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    & > div:first-child{
      margin-right: 15px;
    }
  }
}
.section {
  position: relative;
  padding: 0px 50px;
  height: 84vh;
  // width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .header {
    text-align: center;

    h2 {
      font-size: 42px;
      line-height: 57.6px;
      font-weight: 300;
      margin-top: 40px;
      margin-bottom: 12px;
    }

    p {
      font-weight: 300;
      font-size: 0.75rem;
      // line-height: 24px;
      // margin-bottom: 16px;
    }
  }

  .main {
    margin-top: 25px;
   & > p {
      font-weight: 500;
      font-size: 1rem;
      span{
        height: 10px;
        width: 10px;
      }
    }

    .subHeader{
      font-size: 0.85rem;
      margin-bottom: 7px;
    }

    .infoSection {
      display: flex;
      align-items: center;
      cursor: pointer;

      h6 {
        font-size: 10px;
        font-weight: 500;
        margin-left: 8px;
      }
    }

    .button {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 12px;
      row-gap: 12px;
      margin-top: 16px;

      h6 {
        color: #635f5f;
        font-size: 10px;
      }

      .buttonText {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #2e2b2b;
        border-radius: 6px;
        padding: 14px;
        margin-bottom: 14px;
      }

      .buttonImage {
        width: 100%;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 16px;
      }
    }
  }

  Button {
    position: relative;
    width: 100%;
    margin: 25px auto 16px;
    bottom: 0px;

    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      padding: 5px;
      .svg {
        width: 24px;
      }
    }
  }

  @include smd {
    padding: 0px 30px;
    // height: 45vh;
    overflow-y: hidden;
    font-size: 8px;
  }

  @include md {
    padding: 0px 45px;
  }
}
