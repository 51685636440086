@import '../../../../scss/queries.scss';
.section {
  // position: relative;
  padding: 0px 40px;
  // height: 78vh;
  height: 85%;
  // width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include smd {
    padding: 0px 30px;
    overflow-y: hidden;
    height: 100%;
  }
  @include md {
    padding: 0px 45px;
  }
  .header {
    text-align: center;
    h2 {
      font-size: 42px;
      line-height: 40px;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 4px;
    }
    p {
      font-weight: 300;
      font-size: 0.75rem;
      // line-height: 24px;
      // margin-bottom: 16px;
      // span {
      //   font-weight: 300;
      //   font-size: 16px;
      //   margin-left: 16px;
      //   text-decoration: underline;
      // }
    }
  }
}

.main {
  margin-top: 25px;
  &--subMain {
    margin-top: 20px;
  }
  .sizes {
    margin-bottom: 10px;
  }
  .subTitle {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .subHeader {
    font-size: 0.85rem;
    margin-bottom: 7px;
  }
  .button {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    row-gap: 12px;
    margin-top: 10px;
    .buttonText {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .buttonImage {
      width: 100%;
    }
    span {
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 0px;
    }
    .inputBlock {
      p {
        font-size: 0.85rem;
        margin-bottom: 7px;
      }
    }
  }
  Button {
    position: relative;
    width: 100%;
    margin: 25px auto 16px;
    bottom: 0px;
    font-weight: 500;
    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      padding: 4px;
      .svg {
        width: 24px;
      }
    }
  }
}

.infoText {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  span {
    text-align: center;
    justify-content: center;
    font-size: 0.75rem;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 5px;
    margin-right: 4px;
  }
}

.infoSection {
  display: flex;
  font-size: 0.75rem;
  cursor: pointer;
  align-items: center;
  font-weight: 300;
  h6 {
    font-weight: 400;
    margin-left: 8px;
    font-size: 0.56rem;
  }
  span {
    position: relative;
    &::after {
      content: ' ';
      border-bottom: 1px solid black;
      width: 100%;
      left: 0;
      position: absolute;
      bottom: -2px;
    }
    font-style: italic;
  }
}

.ibm_errechne {
  margin-bottom: 45px;
  h4 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0px;
    margin-bottom: 20px;
  }
  p {
    font-size: 0.85rem;
    font-weight: 300;
    &:last-child {
      span {
        font-size: 1.3rem;
        font-weight: 500;
        margin-left: 5px;
      }
    }
  }
  input {
    appearance: none;
    border: 0px;
    border-bottom: 1px solid;
    width: 50px;
    outline: none;
    color: black;
  }
  span {
    font-size: 0.75rem;
    font-weight: 300;
  }
  div {
    margin-bottom: 5px;
  }
}

.ibm_weiter {
  h5 {
    font-size: 0.85rem;
    margin-bottom: 15px;
  }
  p {
    font-size: 0.75rem;
  }
}
