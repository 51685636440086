@import "../../../scss/queries.scss";

.section {
  overflow: hidden;
  height: 92vh;
  @include smd {
    height: 100%;
  }
  .header {
    text-align: center;

    h2 {
      font-size: 48px;
      line-height: 57.6px;
      font-weight: 400;
      margin-top: 40px;
      margin-bottom: 16px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .main {
    margin-top: 50px;
    span {
      font-weight: 500;
      display: block;
      margin-bottom: 24px;
    }

    .button {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 12px;
      row-gap: 12px;
    }
  }

  Button {
    position: relative;
    width: 100%;
    margin: 50px auto 16px;
    bottom: 0px;

    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      padding: 4px;
    }
  }
}
