$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;
// Very Large tablets and desktops
$screen-xxl-min: 1700px;
// Small devices
@mixin xsm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin xmd {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-xl-min}) {
    @content;
  }
}

//Small and Medium devies
@mixin smd {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}
