@import '../../../../scss/queries.scss';
.section {
  // position: relative;
  padding: 0px 50px;
  height: 80vh;
  // width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include md {
    padding: 0px 45px;
  }

  @include smd {
    padding: 0px 30px;
  }

  .header {
    text-align: center;

    h2 {
      font-size: 48px;
      line-height: 57.6px;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 4px;
    }

    p {
      font-weight: 300;
      font-size: 0.75rem;
      // line-height: 24px;
      // margin-bottom: 16px;

      span {
        font-weight: 500;
        font-size: 16px;
        margin-left: 16px;
        text-decoration: underline;
      }
    }
  }

  .main {
    margin-top: 25px;
    span {
      font-weight: 500;
      font-size: 16px;
      // margin-bottom: 16px;
    }

    .subTitle {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0px;
    }

    .subHeader {
      font-size: 0.8rem;
      margin-bottom: 7px;
      white-space: nowrap;
    }

    .infoSection {
      display: flex;
      align-items: center;
      cursor: pointer;

      h6 {
        font-size: 10px;
        font-weight: 500;
        margin-left: 8px;
      }
    }

    .button {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 12px;
      //    row-gap:12px ;
      margin-top: 10px;

      h6 {
        color: #635f5f;
        font-size: 10px;
      }

      .buttonImage {
        width: 100%;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 0px;
      }
    }
  }

  Button {
    position: relative;
    width: 100%;
    margin: 50px auto 16px;
    bottom: 0px;

    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      padding: 4px;
    }
  }

  @include smd {
    padding: 0px 40px;
    font-size: 8px;
  }
}

.infoSlide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  color: black;
  height: 100vh;
  font-size: 30px;
  width: 100%;
  text-align: left;
  // display: flex;
  // flex-direction: column;
  padding: 0px 50px;
  transition: 850ms;

  @include smd {
    padding: 0px 30px;
  }

  @include md {
    padding: 0px 45px;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    font-size: 15px;
  }

  .infoCloseButton {
    cursor: pointer;
    // position: relative;
    width: 100%;
    margin: 25px auto 16px;
    // right: 0px;
    // font-weight: 500;
    // border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      margin: 0px;
      font-weight: 800;
      margin-right: 8px;
    }

    .arrow {
      border: 1px solid black;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      padding: 4px;
      .svg {
        width: 24px;
      }
    }
  }

  .infoText {
    margin-bottom: 50px;
  }
}
