@import '../../scss/queries.scss';
.container {
  width: calc(100% - 400px);
  display: flex;
  flex-direction: column;
  padding: 10px 28px 0px;
  position: relative;
  overflow: hidden;
  @include smd {
    height: 37vh;
    margin-bottom: 45px;
  }
  @include smd {
    width: 100%;
    padding: 0px;
  }
}
